import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import { StaticImage } from 'gatsby-plugin-image'

const EspaceMedias = ({ location }) => {
  const metaTitle = 'Espace médias & pros | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Espace réservé aux médias, comprenant formulaire de demande d’accréditation, revue de presse, archives photos, pochette de presse et communiqués'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, médias, kit média, entrevues, journalistes'

  return (
    <Wolflayout mobileTitle={'Médias & Pros'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} href={location.href} />
      <Banner
        title="Espace"
        titleLine2= "médias & pros"
      />

      <section className='mt-20'>
        <center>
          <div className='main-page-project'>
            <div className="flex flex-col md:grid md:grid-cols-3 md:grid-rows-1 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-0 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
              <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdjLrqQ9DPvEG8xLXFblvdcnvCxDhrfD0LCqVeQ_kDD1laj7Q/viewform?usp=header" 
                  target='_blank' 
                  rel="noreferrer noopener">
                <StaticImage src="../images/box-devenirbenevole.jpg" alt="Image d'un bénévole" className="w-full h-full object-cover" />
                <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-yellow-wolf2025 text-white !text-left text-xl py-2 flex flex-col">
                  <h2>Accréditation<br/>2025</h2>
                  <p>Remplir l'un des formulaires suivants.<br/>Date limite : 15 juin 2025</p>
                  <div className='w-full !mb-9 flex'>
                    <p className="main-page-project-box-inner-more">Médias</p>
                    <a className="main-page-project-box-inner-more ml-4" href="https://forms.gle/4pWMBuW6WEg32z1V7" target='_blank' rel="noreferrer noopener">Pro</a>
                  </div>
                </div>
              </a>
              <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" href="/photos-videos">
                <StaticImage src="../images/box-developpementdurable.jpg" alt="Image de personnes se serrant dans leurs bras" className="w-full h-full object-cover" />
                <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-pink-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                  <h2>Photos<br/>du Festif!</h2>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.</p> */}
                  <div className='w-full'>
                    <p className="main-page-project-box-inner-more">Lire les infos</p>
                  </div>
                </div>
              </a>
              <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                  href="https://drive.google.com/drive/folders/1mIMagTt_Hmr3UR2DvEwKh9j2kmp9M4En"
                  target='_blank' 
                  rel="noreferrer noopener">
                <StaticImage src="../images/box-zonedesservices.jpg" alt="Image représentant les services offerts par le Festif!" className="w-full h-full object-cover" />
                <div className="main-page-project-box-inner absolute bottom-0 left-0 w-full my-auto bg-blue-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                  <h2>Pochette de<br/>presse 2025</h2>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus</p> */}
                  <div className='w-full'>
                    <p className="main-page-project-box-inner-more">Lire les infos</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </center>

        <div className='text-center mt-3'>
          <StaticImage className="max-w-[250px] max-h-[125px]" imgClassName="!object-contain object-left invert" src="../images/partenaires/centrale_alternative.png" alt="Logo Central Alternative" placeholder="none"/>
        </div>

      </section>
    </Wolflayout>
  )
}

export default EspaceMedias
